import React, { ForwardedRef } from "react";
import { ControlsButton, VideoControlsWrapper } from "./styles";
import { useApp } from "../../state/app";

type VideoControlsProps = {
  show: boolean;
  togglePlay: (e: React.SyntheticEvent) => void;
  muted: boolean;
  toggleMute: () => void;
  rewind: () => void;
  fastForward: () => void;
  caption: boolean;
  toggleCaption: () => void;
  audioDescription: boolean;
  toggleAudioDescription: () => void;
  transcript: boolean;
  toggleTranscript: () => void;
  playing: boolean;
};

export default React.forwardRef(function VideoControls(
  {
    show,
    togglePlay,
    toggleMute,
    muted,
    rewind,
    fastForward,
    caption,
    toggleCaption,
    audioDescription,
    toggleAudioDescription,
    playing,
    transcript,
    toggleTranscript,
  }: VideoControlsProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const showSetting = useApp((state) => state.showSetting);
  const [active, setActive] = React.useState(-1);

  if (!show) return <></>;

  return (
    <VideoControlsWrapper ref={ref} show={show}>
      <ControlsButton
        active={muted}
        backgroundImage={"assets/mute.svg"}
        focus={active === 0}
        onClick={toggleMute}
        aria-labelledby="mute"
        onFocus={() => setActive(0)}
        onMouseOver={() => setActive(0)}
        onBlur={() => setActive(-1)}
        onMouseLeave={() => setActive(-1)}
        tabIndex={showSetting ? -1 : 0}
      >
        Mute On/Off
        <span id="mute">{!muted ? "Mute" : "Unmute"}</span>
      </ControlsButton>
      <ControlsButton
        backgroundImage={"assets/back-10.svg"}
        focus={active === 1}
        onClick={rewind}
        aria-labelledby="back"
        onFocus={() => setActive(1)}
        onMouseOver={() => setActive(1)}
        onBlur={() => setActive(-1)}
        onMouseLeave={() => setActive(-1)}
      >
        Back 10 Seconds
        <span id="back">Back 10 Seconds</span>
      </ControlsButton>
      <ControlsButton
        active={!playing}
        backgroundImage={"assets/pause-play.svg"}
        focus={active === 2}
        onClick={togglePlay}
        title="play"
        aria-labelledby="play"
        onFocus={() => setActive(2)}
        onMouseOver={() => setActive(2)}
        onBlur={() => setActive(-1)}
        onMouseLeave={() => setActive(-1)}
      >
        {<span id="play">{!playing ? "Play" : "Pause"}</span>}
      </ControlsButton>
      <ControlsButton
        backgroundImage={"assets/forward-10.svg"}
        focus={active === 3}
        onClick={fastForward}
        aria-labelledby="forward"
        onFocus={() => setActive(3)}
        onMouseOver={() => setActive(3)}
        onBlur={() => setActive(-1)}
        onMouseLeave={() => setActive(-1)}
      >
        Forward 10 Seconds
        <span id="forward">Forward 10 Seconds</span>
      </ControlsButton>
      <ControlsButton
        specialActive={caption}
        backgroundImage={"assets/captions-off.svg"}
        focus={active === 4}
        onClick={toggleCaption}
        aria-labelledby="caption"
        onFocus={() => setActive(4)}
        onMouseOver={() => setActive(4)}
        onBlur={() => setActive(-1)}
        onMouseLeave={() => setActive(-1)}
      >
        Caption On/Off
        <span id="caption">{!caption ? "Caption On" : "Caption Off"}</span>
      </ControlsButton>
      <ControlsButton
        active={audioDescription}
        specialActive={audioDescription}
        backgroundImage={"assets/audio-description.svg"}
        focus={active === 5}
        onClick={toggleAudioDescription}
        aria-labelledby="audio-description"
        onFocus={() => setActive(5)}
        onMouseOver={() => setActive(5)}
        onBlur={() => setActive(-1)}
        onMouseLeave={() => setActive(-1)}
      >
        Audio Description
        <span id="audio-description">Audio Description</span>
      </ControlsButton>
      {/* note: removed per Will Coleman's request */}
      {/*<ControlsButton*/}
      {/*  active={transcript}*/}
      {/*  specialActive={transcript}*/}
      {/*  backgroundImage={"assets/transcript.svg"}*/}
      {/*  focus={active === 6}*/}
      {/*  onClick={toggleTranscript}*/}
      {/*  aria-labelledby="transcript"*/}
      {/*  onFocus={() => setActive(6)}*/}
      {/*  onMouseOver={() => setActive(6)}*/}
      {/*  onBlur={() => setActive(-1)}*/}
      {/*  onMouseLeave={() => setActive(-1)}*/}
      {/*>*/}
      {/*  Transcript*/}
      {/*  <span id="transcript">Transcript</span>*/}
      {/*</ControlsButton>*/}
    </VideoControlsWrapper>
  );
});
