import styled from "styled-components";

type Props = {
  $open: boolean;
};

export const TranscriptText = styled.div`
  white-space: pre-wrap;
  margin-top: 1em;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
`;

export const TranscriptClose = styled.button`
  all: unset;
  position: fixed;
  top: 15px;
  right: 25px;
  font-size: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #000000;
  background-color: #ffffff;
  border-radius: 50%;

  span {
    translate: 0 -0.075em;
  }

  &:focus-visible {
    outline: 2px solid #ffffff;
    outline-offset: 2px;
  }
`;

export const TranscriptBody = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 700px;
  padding: 20px;
`;

export const TranscriptInner = styled.div`
  padding: 20px;
  position: relative;
`;

export const TranscriptWrapper = styled.dialog<Props>`
  z-index: 9999;
  width: 100%;
  height: 100%;
  inset: 0;
  padding: 0;
  margin: 0;
  max-width: none;
  max-height: none;
  display: grid;
  place-items: center;
  border: none;
  background-color: transparent;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  visibility: ${(props) => {
    return props.$open ? "visible" : "hidden";
  }};
  transition: ${(props) => {
    return props.$open ? "visibility 0s 0s" : "visibility 0s 450ms";
  }};
`;
