import React from "react";
import { SceneInner, SceneWrapper, SettingsCog } from "./styles";
import Settings from "../settings/settings";
import { useApp } from "../../state/app";
import "@reach/tooltip/styles.css";
import Transcript from "../transcript/transcript";

type Props = {
  children: any;
  showSettings?: boolean;
};
export default function Scene({ children, showSettings = true }: Props) {
  const { showSetting, setShowSetting, transcript } = useApp();
  const ref = React.useRef<HTMLButtonElement>(null);
  const mountedRef = React.useRef(false);
  const [mouseOver, setMouseOver] = React.useState(false);

  React.useEffect(() => {
    if (!showSetting && ref.current && mountedRef.current) {
      ref.current.focus();
    }
    mountedRef.current = true;
  }, [showSetting]);

  return (
    <SceneWrapper>
      {showSettings && !transcript && (
        <SettingsCog
          onClick={() => setShowSetting(true)}
          onMouseOver={() => setMouseOver(true)}
          onMouseLeave={() => setMouseOver(false)}
          onFocus={() => setMouseOver(true)}
          onBlur={() => setMouseOver(false)}
          tabIndex={showSetting ? -1 : 0}
          aria-describedby="menu"
          aria-haspopup={true}
          aria-hidden={showSetting}
          focus={mouseOver}
          ref={ref}
        >
          <span id="menu">Menu</span>
        </SettingsCog>
      )}
      {showSetting && (
        <Settings open={showSetting} setOpen={() => setShowSetting(false)} />
      )}
      <Transcript />
      <SceneInner>{children}</SceneInner>
    </SceneWrapper>
  );
}
