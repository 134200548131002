import React from "react";
import { SummaryWrapper, Text } from "./styles";
import Video from "./video";
import { Container } from "../styles";
import useAppLogic from "../../../hooks/use-app-logic";
import { LocaleValue } from "../../../types";

type Props = {
  text: LocaleValue;
  video: string;
  subtitle?: string;
};

export default function Summary({ text, video, subtitle = "" }: Props) {
  const { language } =
    useAppLogic();
  return (
    <SummaryWrapper>
      <Container>
        <Text dangerouslySetInnerHTML={{ __html: text?.[language] ?? "" }} />
        <Video videoSrc={video} subtitle={subtitle} />
      </Container>
    </SummaryWrapper>
  );
}
