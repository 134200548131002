import { scorm as api } from "@gamestdio/scorm";

type Data = null | {
  [prop: string]: any;
};

export enum Status {
  completed = "completed",
}

const IS_IOS =
  navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);

api.configure({
  version: "1.2",
  handleExitMode: true,
  handleCompletionStatus: true,
  debug: true,
});

// @ts-expect-error
window.SCORM_DEBUG = api;

const scorm = {
  init() {
    api.initialize();

    function onBeforeUnload() {
      api.commit();
      api.terminate();
    }

    window.removeEventListener("beforeunload", onBeforeUnload);
    window.addEventListener("beforeunload", onBeforeUnload);

    if (IS_IOS) {
      window.removeEventListener("unload", onBeforeUnload);
      window.addEventListener("unload", onBeforeUnload);
      window.removeEventListener("pagehide", onBeforeUnload);
      window.addEventListener("pagehide", onBeforeUnload);
    }
  },

  onConnected(fn: Function) {
    if (this.isConnected()) {
      fn();
    } else {
      requestAnimationFrame(() => this.onConnected(fn));
    }
  },

  isConnected(): boolean {
    return api.isActive;
  },

  getData(): Data {
    try {
      return JSON.parse(api.get("cmi.suspend_data") ?? "{}");
    } catch {
      return null;
    }
  },

  setData(data: Data) {
    const currentData = this.getData();
    api.set(
      "cmi.suspend_data",
      data === null ? "" : JSON.stringify({ ...currentData, ...data }),
    );
    api.commit();
  },

  getProgress(): number {
    const score = parseFloat(api.get("cmi.core.score.raw"));
    return isNaN(score) ? 0 : score; // 0 - 100
  },

  setProgress(progress: number) {
    api.set("cmi.core.score.raw", progress);
    api.commit();
  },

  setStatus(status: Status) {
    api.set("cmi.core.lesson_status", status);
    api.commit();
  },

  getStatus(): Status {
    return api.get("cmi.core.lesson_status") as Status;
  },

  complete() {
    this.setProgress(100);
    this.setStatus(Status.completed);
    api.commit();
  },
};

export default scorm;
