import React from "react";
import * as Style from "./transcript.style";
import { useApp } from "../../state/app";

function formatTranscriptText(transcriptText: string) {
  // console.log(transcriptText);
  return transcriptText
    .replace(/^WEBVTT/, "")
    .replace(/^\d+\s+/gm, "")
    .split(/\n\n/)
    .filter((line) => line.trim().length > 0)
    .map((line, index) => {
      const [timestamps, ...lines] = line.split("\n");
      const text = lines.join(" ");
      const [from] = timestamps.split(" --> ");
      const timestamp = from
        .split(":")
        .map((time) => `${parseInt(time)}`.padStart(2, "0"))
        .join(":");
      return `[${timestamp}] ${text}`;
    })
    .join("\n\n");
}

export default function Transcript() {
  const transcript = useApp((state) => state.transcript);

  const dialogRef = React.useRef<HTMLDialogElement>(null);

  const [transcriptText, setTranscriptText] = React.useState("");

  React.useEffect(() => {
    if (transcript) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
    const video = document.querySelector("video");
    const track = video?.querySelector("track");
    const vtt = track?.getAttribute("src");
    if (vtt) {
      fetch(vtt)
        .then((res) => res.text())
        .then((transcriptText) => {
          setTranscriptText(formatTranscriptText(transcriptText));
        })
        .catch(console.error);
    }
  }, [transcript]);

  React.useEffect(() => {
    const dialog = dialogRef.current;
    if (dialog === null) return;

    function onClose() {
      useApp.getState().setTranscript(false);
    }

    dialog.addEventListener("close", onClose);
    return () => {
      dialog.removeEventListener("close", onClose);
    };
  }, []);

  return (
    <Style.TranscriptWrapper
      ref={dialogRef}
      $open={transcript}
      id="Transcript"
      data-title="Transcript"
      aria-label="Transcript"
    >
      <Style.TranscriptInner>
        <Style.TranscriptClose
          onClick={() => {
            useApp.getState().setTranscript(false);
          }}
        >
          <span>&times;</span>
        </Style.TranscriptClose>
        <Style.TranscriptBody>
          <Style.TranscriptText>{transcriptText}</Style.TranscriptText>
        </Style.TranscriptBody>
      </Style.TranscriptInner>
    </Style.TranscriptWrapper>
  );
}
