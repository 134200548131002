import AnimatePlay from "./animated-play";
import VideoControls from "./video-controls";
import {
  AnswerWrapper,
  CountDown,
  Decision,
  Decisions,
  FieldSet,
  Question,
  Skip,
  VideoWrapper,
} from "./styles";
import React, { useRef } from "react";
import useVideoControls from "../../hooks/use-video-controls";
import { Answer } from "../../types";
import { useApp } from "../../state/app";

type Props = {
  sceneId: string;
  videoSrc: string;
  audioDescriptionVideo: string;
  subtitle: string;
  answers?: Answer[];
  answersTime?: number;
  answersShow?: string;
  countDownColor?: string;
  forceSubtitles?: boolean | { [locale: string]: boolean };
  skippableVideo: boolean;
  question?: string;
};

export default function Mp4Video({
  sceneId,
  videoSrc,
  subtitle = "",
  answers,
  answersTime,
  answersShow,
  skippableVideo,
  question,
  forceSubtitles = false,
}: Props) {
  const showSetting = useApp((state) => state.showSetting);
  const autoPlayVideo = useApp((state) => state.autoPlayVideo);
  const [isOn, setIsOn] = React.useState(false);

  const {
    videoRef,
    muted,
    togglePlay,
    handleEnded,
    videoEnded,
    language,
    playing,
    playAnimation,
    showDecisions,
    fastForward,
    toggleCaption,
    toggleAudioDescription,
    handleDecisionSelected,
    caption,
    audioDescription,
    toggleMute,
    rewind,
    transcript,
    toggleTranscript,
  } = useVideoControls(forceSubtitles, answersShow, answers, videoSrc, isOn);
  const questionRef = React.useRef<HTMLFieldSetElement>(null);
  const controlRef = useRef<HTMLDivElement | null>(null);

  React.useMemo(() => {
    document.title = question ? question : "Video Player";
  }, [question]);

  const showPanel = React.useCallback(() => {
    if (isOn) return;
    let timer;
    const setDelay = () => {
      timer = setTimeout(() => {
        videoRef.current?.focus();
        setIsOn(false);
      }, 5000);
    };
    clearTimeout(timer);
    setIsOn(true);
    controlRef.current?.blur();
    setDelay();
  }, [isOn, videoRef]);

  React.useEffect(() => {
    if (questionRef.current === null) return;
    if (showDecisions) {
      questionRef.current.focus();
    }
  }, [showDecisions]);

  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Tab") showPanel();
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showPanel]);

  React.useEffect(() => {
    videoRef.current?.focus();
  }, [videoRef]);

  React.useEffect(() => {
    if (!videoRef.current) return;
    if (isOn || (!playing && !videoEnded)) {
      controlRef.current?.blur();
      videoRef.current?.focus();
    } else {
      videoRef.current?.blur();
      controlRef.current?.focus();
    }
  }, [videoRef, isOn, playing, videoEnded]);

  const videoTimeRef = React.useRef(0);

  React.useEffect(() => {
    const video = videoRef.current;

    let nextTick: number;

    function tick() {
      nextTick = requestAnimationFrame(tick);
      if (video === null) return;
      videoTimeRef.current = video.currentTime;
    }

    nextTick = requestAnimationFrame(tick);
    return () => {
      cancelAnimationFrame(nextTick);
    };
  }, [audioDescription]);

  React.useEffect(() => {
    const video = videoRef.current;
    if (video === null) return;
    if (!isFinite(videoTimeRef.current)) return;
    video.currentTime = videoTimeRef.current;
  }, [videoSrc]);

  React.useEffect(() => {
    const video = videoRef.current;
    if (video === null) return;
    if (autoPlayVideo) {
      video.play().catch(console.error);
    } else {
      video.pause();
    }
  }, [autoPlayVideo]);

  React.useEffect(() => {
    if (transcript) {
      togglePlay(false);
    }
  }, [transcript]);

  return (
    <VideoWrapper>
      <video
        playsInline
        autoPlay={autoPlayVideo}
        ref={videoRef}
        controls={false}
        muted={muted}
        onClick={togglePlay}
        onEnded={handleEnded}
        onMouseMove={showPanel}
        style={{ pointerEvents: videoEnded ? "none" : "all" }}
        crossOrigin={"anonymous"}
        src={videoSrc}
        data-item="mp4"
      >
        <track
          label={language}
          kind="captions"
          srcLang={language}
          src={subtitle}
          default
        />
      </video>
      {playAnimation && playing && <AnimatePlay play={playing} />}
      {playAnimation && !playing && <AnimatePlay play={playing} />}

      {(isOn || (!playing && !videoEnded) || !autoPlayVideo) &&
        !showSetting &&
        !showDecisions && (
          <VideoControls
            show={isOn || (!playing && !videoEnded) || !autoPlayVideo}
            togglePlay={togglePlay}
            toggleMute={toggleMute}
            muted={muted}
            rewind={rewind}
            fastForward={fastForward}
            caption={caption}
            toggleCaption={toggleCaption}
            audioDescription={audioDescription}
            toggleAudioDescription={toggleAudioDescription}
            transcript={transcript}
            toggleTranscript={toggleTranscript}
            playing={playing}
            ref={controlRef}
          />
        )}
      <FieldSet
        showDecisions={showDecisions}
        showQuestion={!!question && showDecisions}
        aria-hidden={!showDecisions || showSetting}
        tabIndex={showDecisions && !showSetting ? 0 : -1}
        ref={questionRef}
      >
        {question && <legend aria-hidden={showSetting}>{question}</legend>}
        <Decisions showDecisions={showDecisions}>
          <CountDown
            show={showDecisions || answersTime === 0}
            countTimeTime={answersTime}
          />
          {answers &&
            answers.length > 1 &&
            answers.map((answer, index) => {
              const text = answer?.text?.[language] ?? "";
              return (
                <Decision
                  tabIndex={showDecisions && !showSetting ? 0 : -1}
                  aria-hidden={showSetting}
                  onClick={() => {
                    handleDecisionSelected({
                      sceneId,
                      answerSceneId: answer.sceneId,
                      text,
                    });
                  }}
                  key={index}
                >
                  <AnswerWrapper>{text}</AnswerWrapper>
                </Decision>
              );
            })}
        </Decisions>
      </FieldSet>

      {skippableVideo && (
        <Skip onClick={handleEnded}>
          {answers?.[0]?.text?.[language] ?? "Skip introduction"}
        </Skip>
      )}
    </VideoWrapper>
  );
}
