import React from "react";
import {
  ButtonContainer,
  Line,
  TextLinkContainer,
  TextLinkWrapper,
  TextWrapper,
  TitleWrapper,
} from "./styles";
import Html from "../html/html";
import Button from "../button/button";
import { Answer, LocaleValue, SceneDataProps } from "../../types";
import { useApp } from "../../state/app";
import Animate from "../animate/animate";
import { useHistory } from "react-router-dom";
import { MODE } from "../../config/consts";
import scorm from "../../services/scorm";

type Props = {
  sceneId: string;
  title?: LocaleValue;
  description: LocaleValue;
  descriptionColor?: string;
  titleColor?: string;
  nextButtonBackground?: string;
  nextButtonColor?: string;
  answers: Answer[];
  backgroundColor?: string;
  lastScene?: boolean;
};

export default function TextLink({
  sceneId,
  title,
  description,
  descriptionColor = "#fff",
  titleColor = "#fff",
  nextButtonBackground,
  nextButtonColor,
  answers,
  backgroundColor = "#2d2d2d",
  lastScene = false,
}: Props) {
  const { setUserAnswer, projectID, debriefId, debriefMode, language } =
    useApp();
  const history = useHistory();

  React.useMemo(() => {
    if (!title) return;
    document.title = "Context setting";
  }, [title]);

  const handleDecisionSelected = React.useCallback(
    (sceneData: SceneDataProps) => {
      const { userAnswer } = useApp.getState();
      if (debriefMode && debriefId) {
        history.push(
          MODE.startsWith("scorm") || MODE.startsWith("web")
            ? `/scene/${debriefId}`
            : `/${projectID}/scene/${debriefId}`,
        );
      } else {
        history.push(
          MODE.startsWith("scorm") || MODE.startsWith("web")
            ? `/scene/${sceneData.answerSceneId}`
            : `/${projectID}/scene/${sceneData.answerSceneId}`,
        );
      }
      userAnswer[sceneData.sceneId] = sceneData.text;
      setUserAnswer(userAnswer);
    },
    [history, setUserAnswer, debriefId, debriefMode, projectID],
  );

  const focusRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    focusRef.current?.focus();
  }, []);

  React.useEffect(() => {
    if (!MODE.startsWith("scorm")) return;
    if (!scorm.isConnected()) return;
    if (lastScene) {
      scorm.complete();
    }
  }, [lastScene]);

  return (
    <TextLinkWrapper style={{ backgroundColor }} ref={focusRef}>
      <Animate />
      <TextLinkContainer>
        <TextWrapper>
          {title && Object.values(title)[0] && (
            <TitleWrapper>
              <Html
                html={`<h1>${title?.[language] ?? ""}</h1>`}
                style={{ color: titleColor, textAlign: "center" }}
              />
              <Line />
            </TitleWrapper>
          )}
          <Html
            html={description?.[language] ?? ""}
            style={{ color: descriptionColor }}
          />
        </TextWrapper>
        <ButtonContainer>
          {answers.length > 0 &&
            answers.map((answer, index) => {
              const text = answer?.text?.[language] ?? "";
              return (
                <Button
                  backgroundColor={nextButtonBackground}
                  color={nextButtonColor}
                  key={index}
                  onClick={() => {
                    handleDecisionSelected({
                      sceneId,
                      answerSceneId: answer.sceneId,
                      text,
                    });
                  }}
                >
                  {text}
                </Button>
              );
            })}
        </ButtonContainer>
      </TextLinkContainer>
    </TextLinkWrapper>
  );
}
