import styled from "styled-components";
import vars from "../../../styles/vars";
import * as VideoStyle from "../../video/styles"

export const SummaryWrapper = styled.div`
  padding: 1rem 0;

  ${VideoStyle.VideoControlsWrapper} {
    position: absolute;
  }
`;
export const Text = styled.div``;

export const VideoWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 1rem;

  & video {
    position: relative;
    width: 100%;
    height: auto;

    @media (max-aspect-ratio: 16 / 9) {
      width: 100%;
      height: auto;
    }
  }
`;

type ControlsButtonProps = {
  backgroundImage: string;
  active?: boolean;
  specialActive?: boolean;
};

export const ControlsButton = styled.button<ControlsButtonProps>`
  height: 60px;
  width: 60px;
  display: block;
  opacity: 0.9;
  background-size: 100% auto;
  background-position: ${({ active, specialActive }) =>
    specialActive ? "center bottom" : active ? "bottom" : "top"};
  color: transparent !important;
  font-size: 0 !important;
  line-height: 0 !important;
  position: relative;
  overflow: visible;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  border: none;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 500px) {
    height: 45px;
    width: 45px;
  }
`;

type Props = {
  show: boolean;
};

export const VideoControlsWrapper = styled.div<Props>`
  position: absolute;
  bottom: 1vw;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  height: 100px;
  width: calc(100vw - 40px);
  max-width: 520px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 10px;
  pointer-events: ${({ show }) => (show ? "all" : "none")};
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  transition: ${({ show }) =>
    show
      ? "opacity 300ms, visibility 0s 0ms"
      : "opacity 300ms, visibility 0s 300ms"};

  & ${ControlsButton} {
    &:hover {
      & span {
        opacity: 1;
      }
    }

    & span {
      position: absolute;
      font-weight: bold;
      background-color: #fff;
      top: -80px;
      left: 50%;
      transform: translate(-50%);
      color: #000 !important;
      font-size: 18px !important;
      line-height: 1em !important;
      padding: 0.5em 1em;
      transition: opacity 250ms;
      opacity: 0;
      pointer-events: none;
      white-space: nowrap;

      @media (max-width: 500px) {
        top: -40px;
        font-size: 14px !important;
      }

      &:before {
        content: "";
        height: 12px;
        width: 12px;
        background-color: #fff;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%) rotate(45deg);
      }
    }
  }

  @media (max-width: 600px) {
    height: auto;
  }
`;

export const AnimationContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30vw;
  height: 30vw;
  max-height: 120px;
  max-width: 120px;
  transform: translate(-50%, -50%);
  pointer-events: none;
`;

type AnimationPlayProps = {
  play: boolean;
};

export const AnimationPlay = styled.div<AnimationPlayProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scale(0.5);
  background-position: center;
  background-size: cover;
  opacity: 0;
  animation: animatePlayPause 1s;
  background-image: ${({ play }) =>
    `url(${play ? "assets/animate-play.svg" : "assets/animate-pause.svg"})`};

  @keyframes animatePlayPause {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    20% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    99% {
      opacity: 0;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

export const Decision = styled.button`
  width: 100%;
  font-size: 1.3em;
  padding: 1.6em 1.5em 2em 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity 400ms;
  font-weight: bold;
  color: ${vars.colors.white};
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 900px) {
    font-size: 0.9em;
  }

  @media (max-width: 900px) {
    padding: 1em;
  }

  @media (max-height: 400px) {
    font-size: 0.7em;
  }
`;

type DecisionsProps = {
  showDecisions: boolean;
};

export const Decisions = styled.div<DecisionsProps>`
  pointer-events: ${({ showDecisions }) => (showDecisions ? "all" : "none")};
  background-color: #000;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  transform: ${({ showDecisions }) =>
    showDecisions ? "transform: translate(0, 0)" : "translate(0, 100%)"};
  transition: ${({ showDecisions }) =>
    showDecisions ? "transform 800ms" : " transform 400ms"};
  display: flex;
  flex-direction: row;
  justify-content: stretch;

  &:not(:hover) {
    ${Decision} {
      opacity: 1 !important;
    }
  }

  @media (max-width: 700px) {
    & {
      flex-direction: column;
      padding: 20px 0 30px 0;
    }
  }
`;
