import React from "react";
import { Answer, SceneDataProps } from "../types";
import { useHistory } from "react-router-dom";
import { useApp } from "../state/app";
import { MODE } from "../config/consts";

export default function useVideoControls(
  forceSubtitles?: boolean | { [locale: string]: boolean },
  answersShow?: string,
  answers?: Answer[],
  videoSrc?: string,
  isOn?: boolean,
) {
  const history = useHistory();

  const sound = useApp((state) => state.sound);
  const captions = useApp((state) => state.captions);
  const language = useApp((state) => state.language);
  const audioDescription = useApp((state) => state.audioDescription);
  const transcript = useApp((state) => state.transcript);
  const setSound = useApp((state) => state.setSound);
  const setCaptions = useApp((state) => state.setCaptions);
  const setAudioDescription = useApp((state) => state.setAudioDescription);
  const setTranscript = useApp((state) => state.setTranscript);
  const setShowProgress = useApp((state) => state.setShowProgress);
  const projectID = useApp((state) => state.projectID);
  const setUserAnswer = useApp((state) => state.setUserAnswer);
  const debriefMode = useApp((state) => state.debriefMode);
  const debriefId = useApp((state) => state.debriefId);
  const autoPlayVideo = useApp((state) => state.autoPlayVideo);

  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const timeoutRef = React.useRef<NodeJS.Timeout>();
  const [playing, setPlaying] = React.useState(true);
  const [muted, setMuted] = React.useState(false);
  const [caption, setCaption] = React.useState(true);
  const [videoEnded, setVideoEnded] = React.useState(false);
  const [showDecisions, setShowDecisions] = React.useState(false);
  const [playAnimation, setPlayAnimation] = React.useState(false);

  const togglePlay = React.useCallback(
    (eventOrPlaying: React.SyntheticEvent | boolean) => {
      if (typeof eventOrPlaying === "boolean") {
        if (eventOrPlaying) {
          videoRef.current?.play().catch(console.error);
        } else {
          videoRef.current?.pause();
        }
        setPlaying(eventOrPlaying);
        setPlayAnimation(true);
      } else {
        setPlaying((playing) => {
          if (playing) {
            videoRef.current?.pause();
          } else {
            videoRef.current?.play().catch(console.error);
          }
          return !playing;
        });
        setPlayAnimation(true);
      }
    },
    [],
  );

  const toggleMute = React.useCallback(() => {
    const video = videoRef.current;
    if (video === null) return;
    if (muted) {
      video.muted = false;
      setMuted(false);
      setSound(true);
    } else if (!muted) {
      video.muted = true;
      setMuted(true);
      setSound(false);
    }
  }, [muted, setSound]);

  const forceSubtitlesToShow = React.useMemo(() => {
    return typeof forceSubtitles === "object"
      ? forceSubtitles[language]
      : forceSubtitles;
  }, [forceSubtitles, language]);

  const toggleCaption = React.useCallback(() => {
    const video = videoRef.current;
    if (video === null) return;
    if (caption && video.textTracks[0]) {
      if (forceSubtitlesToShow) {
        video.textTracks[0].mode = "showing";
        setCaption(true);
        setCaptions(true);
      } else {
        video.textTracks[0].mode = "hidden";
        setCaption(false);
        setCaptions(false);
      }
    } else if (!caption && video.textTracks[0]) {
      video.textTracks[0].mode = "showing";
      setCaption(true);
      setCaptions(true);
    }
  }, [caption, setCaptions, forceSubtitlesToShow]);

  const toggleAudioDescription = React.useCallback(() => {
    const newAudioDescription = !audioDescription;
    setAudioDescription(newAudioDescription);
  }, [setAudioDescription, audioDescription]);

  const toggleTranscript = React.useCallback(() => {
    const newTranscript = !transcript;
    setTranscript(newTranscript);
  }, [setTranscript, transcript]);

  const rewind = React.useCallback(() => {
    const video = videoRef.current;
    if (video === null) return;
    video.currentTime = video.currentTime - 10;
  }, []);

  const fastForward = React.useCallback(() => {
    const video = videoRef.current;
    if (video === null) return;
    video.currentTime = video.currentTime + 10;
  }, []);

  const handleEnded = React.useCallback(() => {
    if (answers && answers.length === 1) {
      history.push(
        MODE.startsWith("scorm") || MODE.startsWith("web")
          ? `/scene/${answers[0].sceneId}`
          : `/${projectID}/scene/${answers[0].sceneId}`,
      );
      setVideoEnded(true);
    }
  }, [answers, history, projectID]);

  const handleDecisionSelected = React.useCallback(
    (sceneData: SceneDataProps) => {
      if (debriefMode && debriefId) {
        history.push(
          MODE.startsWith("scorm") || MODE.startsWith("web")
            ? `/scene/${debriefId}`
            : `/${projectID}/scene/${debriefId}`,
        );
        const { userAnswer } = useApp.getState();
        userAnswer[sceneData.sceneId] = sceneData.text;
        setUserAnswer(userAnswer);
      } else {
        history.push(
          MODE.startsWith("scorm") || MODE.startsWith("web")
            ? `/scene/${sceneData.answerSceneId}`
            : `/${projectID}/scene/${sceneData.answerSceneId}`,
        );
        const { userAnswer } = useApp.getState();
        userAnswer[sceneData.sceneId] = sceneData.text;
        setUserAnswer(userAnswer);
      }
    },
    [history, setUserAnswer, debriefId, debriefMode, projectID],
  );

  React.useEffect(() => {
    const video = videoRef.current;
    const interval = setInterval(() => {
      if (video === null) return;
      const showTime = parseFloat(answersShow ?? "1");
      if (video.readyState) {
        if (video.currentTime >= video.duration * showTime) {
          if (answers && answers.length > 1) {
            setShowDecisions(true);
            setVideoEnded(true);
          }
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [
    answers,
    history,
    projectID,
    debriefId,
    debriefMode,
    answersShow,
    language,
  ]);

  React.useEffect(() => {
    setMuted(!sound);
    if (forceSubtitlesToShow) {
      setCaption(true);
      setCaptions(true);
    } else {
      setCaption(captions);
    }
  }, [sound, captions, forceSubtitlesToShow, setCaptions]);

  React.useEffect(() => {
    if (answers && answers.length > 2) {
      setShowProgress(true);
      timeoutRef.current = setTimeout(() => {
        setShowProgress(false);
      }, 10000);

      return () => {
        if (timeoutRef.current !== undefined) {
          clearTimeout(timeoutRef.current);
        }
      };
    }
  }, [answers, setShowProgress]);

  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current.textTracks[0].mode = forceSubtitlesToShow
        ? "showing"
        : captions
        ? "showing"
        : "hidden";
    }
  }, [captions, forceSubtitlesToShow]);

  React.useEffect(() => {
    const video = videoRef.current;
    if (video === null) return;
    setMuted(!sound);
    setCaption(forceSubtitlesToShow ? true : captions);
    if (autoPlayVideo) {
      video
        .play()
        .then(() => {
          setPlaying(true);
        })
        .catch(console.error);
    } else {
      video.pause();
      setPlaying(false);
    }
  }, [captions, forceSubtitlesToShow, sound, videoSrc, autoPlayVideo]);

  React.useEffect(() => {
    const video = videoRef.current;
    function keyboardShortCut(e: any) {
      if (video === null) return;
      if (e.key === "k" && isFinite(video.duration)) {
        video.currentTime = video.duration;
      }
      // check if user has pressed space bar
      if (e.keyCode === 32) {
        if (document.activeElement) {
          const attribute = document.activeElement.getAttribute("data-item");
          if (attribute === "mp4") {
            !videoEnded && togglePlay(e);
            e.stopPropagation();
          } else {
            e.preventDefault();
            e.stopPropagation();
          }
        }
      }
    }

    window.addEventListener("keypress", keyboardShortCut);
    return () => window.removeEventListener("keypress", keyboardShortCut);
  }, [togglePlay, videoEnded, isOn]);

  return {
    videoRef,
    muted,
    togglePlay,
    handleEnded,
    videoEnded,
    language,
    playing,
    playAnimation,
    showDecisions,
    fastForward,
    toggleCaption,
    toggleAudioDescription,
    toggleTranscript,
    handleDecisionSelected,
    caption,
    audioDescription,
    transcript,
    toggleMute,
    rewind,
    setPlaying,
  };
}
