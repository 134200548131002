import styled from "styled-components";
import vars from "../../styles/vars";

export const SceneWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: relative;
`;

export const SceneInner = styled.div`
  height: 100%;
  width: 100%;
`;

type SettingsCogProps = {
  focus?: boolean;
};

export const SettingsCog = styled.button<SettingsCogProps>`
  position: fixed;
  height: 70px;
  width: 70px;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 1);
  background-image: url("assets/cog.svg");
  background-size: 45px;
  background-position: center;
  background-repeat: no-repeat;
  top: 2vw;
  right: 2vw;
  z-index: 8888;
  cursor: pointer;

  &:hover {
    background-color: #fff;
  }

  &:focus {
    outline-color: black;
  }

  @media (max-height: 400px) {
    top: 2.3vw;
    right: 2.3vw;
  }

  @media (max-width: 1000px) {
    height: 60px;
    width: 60px;
  }

  @media (max-width: 700px) {
    height: 50px;
    width: 50px;
  }

  @media (max-width: 500px) {
    height: 40px;
    width: 40px;
  }

  @media (max-height: 400px) {
    height: 40px;
    width: 40px;
  }

  & span {
    opacity: ${({ focus }) => (focus ? 1 : 0)};
    position: absolute;
    font-weight: bold;
    background-color: #fff;
    top: 80px;
    left: 50%;
    transform: translate(-50%);
    color: #000 !important;
    font-size: 18px !important;
    line-height: 1em !important;
    padding: 0.5em 1em;
    transition: opacity 250ms;
    pointer-events: none;
    white-space: nowrap;
    outline-color: red !important;

    &:focus {
      outline-color: red !important;
    }

    @media (max-width: 500px) {
      top: -40px;
      font-size: 14px !important;
    }

    &:before {
      content: "";
      height: 12px;
      width: 12px;
      background-color: #fff;
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translate(-50%, 50%) rotate(45deg);
      overflow: hidden;
      line-height: 0.4;
      outline: none;
    }
  }
`;

export const Progress = styled.div`
  font-family: ${vars.fontPrimary};
  background-color: ${vars.colors.black900};
  color: ${vars.colors.white};
  position: absolute;
  font-size: 0.8em;
  z-index: 999999999999;
  padding: 0.6em 1em 0.5em 1em;
  opacity: 0;
  transform: scale(1.3);
  pointer-events: none;
  animation: progress forwards 7s;
  top: 2vw;
  left: 2vw;
  &:focus {
    outline-color: transparent;
  }

  @media (max-width: 600px), (max-height: 600px) {
    top: 3.5vw;
    left: 3.5vw;
  }

  @media (max-height: 400px) {
    top: 2vw;
    left: 2vw;
  }

  @keyframes progress {
    20% {
      opacity: 1;
      transform: scale(1);
    }
    40% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0.8);
    }
  }
`;
