import React from "react";
import { Answer, LocaleValue } from "../../types";
import Mp4Video from "./mp4Video";
import { useApp } from "../../state/app";
import { MODE } from "../../config/consts";
import scorm from "../../services/scorm";

type Props = {
  sceneId: string;
  videoSrc: string;
  audioDescriptionVideo?: string;
  subtitle: string;
  answers?: Answer[];
  answersTime?: number;
  answersShow?: string;
  countDownColor?: string;
  forceSubtitles?: boolean | { [locale: string]: boolean };
  skippableVideo: boolean;
  brightcove?: string;
  brightcoveAudioDescription?: string;
  question?: LocaleValue;
  lastScene?: boolean;
};

export default function Video({
  sceneId,
  videoSrc,
  audioDescriptionVideo,
  subtitle,
  answers,
  answersTime,
  answersShow,
  skippableVideo,
  brightcove,
  brightcoveAudioDescription,
  question,
  forceSubtitles = false,
  lastScene = false,
}: Props) {
  const language = useApp((state) => state.language);
  const audioDescription = useApp((state) => state.audioDescription);
  const [src, setSrc] = React.useState<string>(videoSrc);
  const [currentSubtitle, setCurrentSubtitle] =
    React.useState<string>(subtitle);

  const videoInfo = React.useMemo(() => {
    const url = audioDescription ? brightcoveAudioDescription : brightcove;
    if (!url) return null;
    const matches = url.match(/\d+/g) ?? [];
    return { accountId: matches[0] ?? "", videoId: matches[1] ?? "" };
  }, [brightcove, brightcoveAudioDescription, audioDescription]);

  React.useEffect(() => {
    if (videoInfo === null) return;
    (async () => {
      try {
        let pk: string;
        switch (videoInfo.accountId) {
          case "1418446428001":
            pk = `BCpkADawqM2F2546q8SLRQTQg0dA1pNY5l-SuJc3vJ1kXfELK7c7X_IiyYgbNoGo810_19XqpovvGeVPwZtguMFrmM6-SMMWy-Y8dTMtp_lrGDBD2uMLIbnUti8hUGwHNDcvFEenb3xXEQkC`;
            break;
          default:
            pk = `BCpkADawqM0S8AeV94J3MZMt07XnlPLBdWO_LTAORf4ND9goQ-BJ6EsMA4cnbUuEl5EKtsZdFBB2YU7lFGhXuVoddVEpNwoQnIckWThXSOAqrV2669At6MxBI47DAOxib6SwdanHV0SYTVsm`;
        }
        const res = await fetch(
          `https://edge.api.brightcove.com/playback/v1/accounts/${videoInfo.accountId}/videos/${videoInfo.videoId}`,
          {
            headers: {
              accept: `application/json;pk=${pk}`,
            },
          },
        );
        const data = await res.json();
        const mp4 = data.sources.find((source: any) => {
          return source.container === "MP4" && source.src.includes("https");
        });
        setSrc(mp4?.src);
        const fallback = data.text_tracks?.[0] ?? { srclang: null };
        let fallbackSrc = "";
        if (fallback.srclang === null) {
          fallbackSrc = fallback.src;
        }
        const languageMap = {
          en: "en",
          "en-US": "en",
          "es-419": "es",
          es: "es",
          fr: "fr",
          "fr-CA": "fr-ca",
          "zh-Hant": "zh",
          "zh-Hans": "zh",
          zh: "zh",
        };
        const subtitle =
          (data.text_tracks ?? []).find((track: any) => {
            return (
              track.srclang === language ||
              // @ts-expect-error
              languageMap[track.srclang] === language
            );
          }) ?? {};
        const subtitleSource = subtitle?.sources?.find((source: any) =>
          source?.src?.includes("https"),
        );
        setCurrentSubtitle(
          subtitleSource.src ?? subtitle?.src ?? subtitle ?? fallbackSrc,
        );
      } catch (error) {
        console.error(error);
      }
    })();
  }, [videoInfo, language, subtitle]);

  React.useEffect(() => {
    if (!audioDescriptionVideo) return;
    setSrc(audioDescription ? audioDescriptionVideo : videoSrc);
  }, [audioDescription, audioDescriptionVideo, videoSrc]);

  React.useEffect(() => {
    if (!MODE.startsWith("scorm")) return;
    if (!scorm.isConnected()) return;
    if (lastScene) {
      scorm.complete();
    }
  }, [lastScene]);

  return (
    <Mp4Video
      sceneId={sceneId}
      audioDescriptionVideo={src}
      videoSrc={src}
      subtitle={currentSubtitle}
      answers={answers}
      answersTime={answersTime}
      answersShow={answersShow}
      skippableVideo={skippableVideo}
      forceSubtitles={forceSubtitles}
      question={question?.[language]}
    />
  );
}
