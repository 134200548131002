import styled from "styled-components";
import vars from "../../styles/vars";
import { ButtonWrapper } from "../button/styles";

type Props = {
  open: boolean;
};

export const SettingsWrapper = styled.dialog<Props>`
  all: unset;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: auto !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  z-index: 9999;
  transition: ${({ open }) =>
    open ? "visibility 0s 0s" : "visibility 0s 450ms"};
`;

type SettingsBackgroundProps = {
  show: boolean;
};

export const SettingsBackground = styled.div<SettingsBackgroundProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: ${({ show }) => (show ? 0.9 : 0)};
  transition: opacity 450ms;
  display: ${({ show }) => (show ? "block" : "none")};
`;

type SettingsContentProps = {
  show: boolean;
};

export const SettingsContent = styled.div<SettingsContentProps>`
  margin: auto;
  position: relative;
  background-color: #2d2d2d;
  max-width: 500px;
  width: calc(100vw - 7.6vw);
  box-sizing: border-box;
  padding: 70px 80px;
  transform: ${({ show }) => (show ? "scale(1)" : "scale(1.1)")};
  opacity: ${({ show }) => (show ? 1 : 0)};
  text-align: center;
  transition: transform 450ms, opacity 450ms;
  outline: none;

  &:focus {
    outline: none;
  }

  @media (max-width: 500px) {
    padding: 70px 50px;
  }
`;

export const CloseIcon = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  height: 20px;
  width: 20px;
  background-image: url("assets/close.svg");
  background-position: center;
  background-size: contain;
  color: transparent !important;
  font-size: 0 !important;
  line-height: 0 !important;

  @media (max-width: 500px) {
    height: 18px;
    width: 18px;
  }
`;

export const SettingsTitle = styled.h1`
  color: ${vars.colors.white};
  font-family: ${vars.fontPrimary};
  font-size: 1.2em;
  font-weight: 700;
`;

export const SettingsToggles = styled.div`
  margin: 2em 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CloseButton = styled(ButtonWrapper)`
  color: ${vars.colors.white};
  background-color: var(--color-primary);
  border: solid 1px var(--color-primary);
  font-weight: bold;
  width: 100%;
  margin-bottom: 0.5em;
`;

export const DownloadPDF = styled.button`
  color: ${vars.colors.white};
  background-color: var(--color-primary);
  padding: 0.5em 2.3em;
  border: solid 1px var(--color-primary);
  font-weight: bold;
  width: 100%;
  margin-bottom: 0.5em;
`;

export const SettingsToggleButton = styled.button`
  width: 100%;
  text-align: left;
  height: 50px;
  padding: 0.25em 0.5em 0.25em 1em;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
`;

export const Text = styled.span`
  margin-right: auto;
  font-size: 0.9em;
  color: ${vars.colors.white};
`;

export const Status = styled.span``;

type StatusTextProps = {
  on: number;
};

export const StatusText = styled.span<StatusTextProps>`
  color: ${vars.colors.white};
  font-weight: bold;
  font-size: 0.9em;
`;

type SwitchProps = {
  on: number;
};

export const Switch = styled.span<SwitchProps>`
  background-color: ${({ on }) =>
    on ? vars.colors.black850 : vars.colors.black900};
  transform: translate(0, -3px);
  display: block;
  height: 22px;
  width: 44px;
  border-radius: 12px;
  position: relative;
  transition: background-color 300ms;
`;

export const Icon = styled.span`
  margin-left: 1em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  margin-top: 3px;
`;

type KnobProps = {
  on: number;
};

export const Knob = styled.span<KnobProps>`
  position: absolute;
  height: 20px;
  width: 20px;
  left: ${({ on }) => (on ? "calc(100% - 1px)" : "1px")};
  top: 1px;
  background-color: ${({ on }) =>
    on ? vars.colors.white : vars.colors.white950};
  border-radius: 50px;
  transform: ${({ on }) => (on ? "translate(-100%)" : "translate(0)")};
  transition: left 300ms, transform 300ms, background-color 300ms;
`;
